import gsap from 'gsap';
const hideTime = 0.1;
const buttonSelector = '.js-dropdown-button';
const activeButtonSelector = `${buttonSelector}[aria-expanded="true"]`;
const buttons = document.querySelectorAll(buttonSelector);

function checkActive() {
  const active = document.querySelector(activeButtonSelector);
  if (active) {
    showMenu(active, false);
  }
}

// https://gsap.com/docs/v3/HelperFunctions/helpers/callAfterResize
function callAfterResize(func, delay) {
  let dc = gsap.delayedCall(delay || 0.2, func).pause();
  let handler = () => dc.restart(true);
  window.addEventListener("resize", handler);
  return handler; // in case you want to window.removeEventListener() later
}

function initMatchMedia() {
  let matchMedia = gsap.matchMedia();
  matchMedia.add({
    isDesktop: '(min-width: 960px)'
  }, function(context) {
    const { isDesktop } = context.conditions;
  
    context.add('handleEscape', function(e) {
      if (e.code === 'Escape' && isDesktop) {
        hideMenu();
      }
    });
  
    context.add('handleClick', function(e) {
      const clickFromInside = e.target.closest('.js-nav-item');
      if (!clickFromInside && isDesktop) {
        hideMenu();
      }
    });
  
    // Hide list when user presses Escape key or clicks outside list area
    document.addEventListener('keyup', context.handleEscape);
    document.addEventListener('click', context.handleClick);
  
    return function() {
      // make sure to clean up event listeners in the cleanup function!
      document.removeEventListener('keyup', context.handleEscape);
      document.removeEventListener('click', context.handleClick);
    }
  });
}

function hideMenu(el) {
  const button = el || document.querySelector(activeButtonSelector);
  if (!button) return;
  const menu = button.nextElementSibling;
  button.setAttribute('aria-expanded', 'false');
  gsap.to(menu, {
    height: 0,
    duration: hideTime,
    ease: 'none',
    autoAlpha: 0
  });
}

function showMenu(el, animate = true) {
  const button = el;
  const active = document.querySelector(activeButtonSelector);
  if (button !== active) {
    hideMenu(active);
  }
  button.setAttribute('aria-expanded', 'true');
  const menu = button.nextElementSibling;
  const tl = gsap.timeline({delay: active ? hideTime : 0});
  tl.set(menu, {
    height: 'auto',
    autoAlpha: 1
  })
  .from(menu, {
    height: 0,
    duration: animate ? 0.2 : 0,
    ease: 'power2.out',
    immediateRender: false,
    autoAlpha: 0
  });
}

function handleClick(e) {
  const button = e.target.closest(buttonSelector);
  if (!button || button.getAttribute('aria-disabled') === 'true') {
    return;
  }
  // Close active dropdown if user clicks outside
  if (!button) {
    hideMenu();
    return;
  }
  const isActive = button.getAttribute('aria-expanded') === 'true';
  isActive ? hideMenu(button) : showMenu(button);
}

function enable() {
  buttons.forEach(function(button) {
    button.setAttribute('aria-disabled', false);
    button.setAttribute('aria-expanded', false);
  });
}

export default function() {
  enable();
  document.addEventListener('click', handleClick);
  callAfterResize(checkActive)
  initMatchMedia();
}
