import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin)

export default function() {
  document.addEventListener('DOMContentLoaded', function() {
    var scrollToTopBtn = document.getElementById('back-to-top');
    scrollToTopBtn.addEventListener('click', function() {
      gsap.to(window, { duration: 1, scrollTo: 0 });
    });
  });
}