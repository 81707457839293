import { gsap } from 'gsap';
import axios from 'axios';

const body = document.body;
const searchForm = document.getElementById('search');

function showSearch() {
  body.classList.add('search-is-active');
  // Plural because two search buttons - not ideal
  const buttons = document.querySelectorAll('.js-search-button');
  buttons.forEach(button => button.setAttribute('aria-expanded', true));
  const searchInput = searchForm.querySelector('input');
  const tl = gsap.timeline();
  tl.set(searchForm, {
    top() {
      if (window.innerWidth < 960) {
        return document.querySelector('header').offsetHeight;
      } else {
        return document.querySelector('header').offsetHeight + 24;
      }
    }
  })
  tl.fromTo(searchForm, {
    display: 'none',
    opacity: 0,
    y: 10
  }, {
    display: 'flex',
    opacity: 1,
    y: 0,
    duration: 0.2,
    onComplete: () => searchInput.focus()
  });
}

function hideSearch() {
  body.classList.remove('search-is-active');
  const buttons = document.querySelectorAll('.js-search-button');
  buttons.forEach(button => button.setAttribute('aria-expanded', false));
  gsap.to(searchForm, {
    display: 'none',
    opacity: 0,
    y: 10,
    duration: 0.1
  });
}

function toggleSearch() {
  body.classList.contains('search-is-active') ? hideSearch() : showSearch();
}

function ajax(options) {
  const { url, updateHistory = true } = options;
  const body = document.body;
  // Add css class - temporarily disable further clicks on filters and cards
  body.classList.add('loading-ajax-cards');

  const ajaxContainer = document.getElementById('ajax-search-results');

  axios.get(url).then((res) => {
    if (history && updateHistory) {
      history.pushState('', '', url);
    }
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res.data, "text/html");
    const newContainer = resHTML.getElementById('ajax-search-results');
    const newContent = newContainer.innerHTML;
    // Prevent height 'jump' when swapping content
    const height = ajaxContainer.offsetHeight;
    ajaxContainer.style.height = `${height}px`;
    ajaxContainer.innerHTML = newContent;
    ajaxContainer.style.height = `auto`;

    gsap.from('.js-ajax-search-result', {
      opacity: 0,
      y: 10,
      duration: 0.4,
      stagger: 0.1,
    });

    if (paginate) {
      gsap.to(window, {
        scrollTo: {
          y: 0,
          offsetY: () => {
            const header = document.getElementById('header');
            const headerHeight = header.offsetHeight;
            return headerHeight;
          }
        },
        duration: 0.2,
      })
    }
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    body.classList.remove('loading-ajax-cards');
  });
}

function paginate(link) {
  const url = decodeURIComponent(link.href);
  console.log(url);
  ajax({url: url});
}

function handleClick(e) {
  const paginateLink = e.target.closest('.js-search-paginate-link');
  if (paginateLink) {
    e.preventDefault();
    paginate(paginateLink);
    return;
  }

  const button = e.target.closest('.js-search-button');
  const clickFromInside = e.target.closest('#search');
  // If click is outside search form and isn't the search toggle button
  if (!clickFromInside && !button) {
    hideSearch();
    return;
  };
  // If there's no button
  if (!button) return;
  // Else toggle search
  toggleSearch();
}

function handleEscape(e) {
  if (e.key === 'Escape') hideSearch();
}

const search = {
  hideSearch,
  init: function() {
    console.log('here')
    if (!searchForm) return;
    document.addEventListener('click', handleClick);
    document.addEventListener('keyup', handleEscape);
  },
};

export default search;