import { gsap } from 'gsap';

export default function() {
  const list = document.querySelector('.js-looping-list');

  // Check if the list element exists
  if (!list) {
    console.warn('No .js-looping-list element found');
    return;
  }

  const items = list.querySelectorAll('li');
  const total = items.length;

  let current = 0;

  const moveUp = gsap.to(items, {
    yPercent: -100,
    y: -25, // rowGap
    duration: 1.5,
    easing: "sine.inOut",
    onComplete: () => {
      list.appendChild(items[current]);
      if (current < total - 1) {
        current++;
      } else {
        current = 0;
      }
      moveUp.restart();
    }
  });
};
