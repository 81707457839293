const headerEl = document.querySelector('.js-header');
const scrollWatcher = document.createElement('div');

export default function() {

  scrollWatcher.setAttribute('data-scroll-watcher', '');
  headerEl.before(scrollWatcher);
  const navObserver = new IntersectionObserver((entries) => {
    headerEl.classList.toggle('header--dark', !entries[0].isIntersecting)
  });

  navObserver.observe(scrollWatcher);

}