// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

import tabbing from './modules/tabbing';
import skip from './modules/skip';
import nav from './modules/nav';
import dropdowns from './modules/dropdowns';
import filters from './modules/filters';
import videos from './modules/videos';
import animtext from './modules/animtext';
import backToTop from './modules/back-to-top';
import loop from './modules/looping-list';
import carousel from './modules/carousel';
import header from './modules/header';
import heroVideo from './modules/hero-video';
import search from './modules/search';

heroVideo();
tabbing();
skip();
nav();
backToTop();
dropdowns();
filters();
videos();
loop();
carousel();
header();
search.init();

const rootEL = document.documentElement;
rootEL.classList.remove('no-js');

// document.addEventListener('DOMContentLoaded', function(e) {
//   document.body.classList.remove('page-is-loading');
// });

window.addEventListener('load', function(e) {
  document.body.classList.remove('page-is-loading');
  animtext();
});

