// Uses GSAP carousel/loop helper function:
// https://gsap.com/docs/v3/HelperFunctions/helpers/seamlessLoop
import { horizontalLoop } from './horizontal-loop.js';
import gsap from 'gsap';

function initCarousel(carousel) {
  let carouselInstance;
  const carouselItems = carousel.querySelectorAll('.js-carousel-item');
  const carouselPrev = carousel.querySelector('.js-carousel-prev');
  const carouselNext = carousel.querySelector('.js-carousel-next');

  // Use gsap.matchMedia (combined with some of our css) to only render a carousel on tablet/desktop 
  // https://gsap.com/docs/v3/GSAP/gsap.matchMedia()/
  window.addEventListener('load', function() {
    let mm = gsap.matchMedia();

    mm.add("(min-width: 300px)", (context) => {
      carouselInstance = horizontalLoop(carouselItems, {
        paused: true,
        repeat: 0,
        paddingRight: 24,
        draggable: true,
      });

      context.add('prev', () => {
        carouselInstance.previous({ duration: 0.4, ease: "power1.inOut" });
      });

      context.add('next', () => {
        carouselInstance.next({ duration: 0.4, ease: "power1.inOut" });
      });
    
      carouselPrev.addEventListener('click', context.prev);
      carouselNext.addEventListener('click', context.next);
      
      // This gets called with the media query no longer matches
      return () => {
        // make sure to clean up event listeners in the cleanup function!
        carouselPrev.removeEventListener('click', context.prev);
        carouselNext.removeEventListener('click', context.next);
      };
    });

    mm.add("(max-width: 799px)", () => {
      // Check if an instance of the carousel exists. If so, remove for mobile:
      if (carouselInstance) {
        carouselInstance.kill();
        gsap.set(carouselItems, {clearProps: true});
      }
    });
  });
}

export default function() {
  const carousels = document.querySelectorAll('.js-carousel');
  if (!carousels.length) return;
  carousels.forEach(carousel => initCarousel(carousel));
}